import classNames from "classnames";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useOrder } from "src/contexts";
import { useGlobalStore, useInstallationStore } from "src/store";
import { ReactHookFormControl } from "src/types";
import {
  FALLBACK_BASE_PRICE,
  FALLBACK_OUTSIDE_LIFT_PRICE,
  FALLBACK_PERMISSION_PRICE,
} from "src/utils/constants";

type Props = {
  control: ReactHookFormControl;
  errors: any;
};

export default function CitySelector({ control, errors }: Props) {
  const { t } = useTranslation();
  const { global, setBasePrice, setPermissionPrice, setWithOutsideLiftPrice } =
    useGlobalStore();
  const { setAmount } = useOrder();
  const {
    installationDetails,
    setInstallationDetails,
    resetInstallationDetails,
  } = useInstallationStore();

  return (
    <div className="form-group">
      <Controller
        control={control}
        name="city"
        render={({ field: { ref, onChange } }) => (
          <Select
            ref={ref}
            id="city"
            placeholder={t("installations:inputs:city:placeholder")}
            options={global.cities}
            isLoading={!global.cities || !global.cities.length}
            defaultValue={global.cities.filter(
              (city) => city.value === installationDetails.city?.id
            )}
            onChange={(e) => {
              resetInstallationDetails();
              setAmount(0);
              const selectedCity = global.cities.find(
                (city) => city.value === e!.value
              )!;
              const formatedCity = {
                value: selectedCity.value,
                label: selectedCity.label,
                basePrice: selectedCity.basePrice,
                withOutsideLiftPrice: selectedCity.withOutsideLiftPrice,
                id: selectedCity.value,
              };

              setBasePrice(selectedCity.basePrice || FALLBACK_BASE_PRICE);
              setPermissionPrice(
                selectedCity.permissionPrice || FALLBACK_PERMISSION_PRICE
              );
              setWithOutsideLiftPrice(
                selectedCity.withOutsideLiftPrice || FALLBACK_OUTSIDE_LIFT_PRICE
              );
              e && setInstallationDetails({ city: formatedCity });
              return onChange(global.cities.map((option) => option.value));
            }}
          />
        )}
      />
      <p
        className={classNames({
          required: true,
          "display-none": !global.showRequired,
        })}
      >
        {errors.city?.message}
      </p>
    </div>
  );
}
