import { useGlobalStore } from "src/store";

export const useBasePrice = () => {
  const { global } = useGlobalStore();
  return global.basePrice;
};

export const usePermissionPrice = () => {
  const { global } = useGlobalStore();
  return global.permissionPrice;
};

export const useWithOutsideLiftPrice = () => {
  const { global } = useGlobalStore();
  return global.withOutsideLiftPrice;
};

/**
 * @deprecated
 */
export const useBasePriceWithTwoSiteBlock = () => {
  const { global } = useGlobalStore();
  return global.basePrice + 40;
};
