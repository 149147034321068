import classNames from "classnames";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useOrder } from "src/contexts";
import {
  usePermissionPrice,
  useWithOutsideLiftPrice,
} from "src/hooks/helperHooks";
import { useGlobalStore, useInstallationStore } from "src/store";
import { ReactHookFormControl, ReactHookFormRegister } from "src/types";
import { TWO_SIDE_BLOCKING_PRICE, purposeOptions } from "src/utils/constants";
import { getAllTime } from "src/utils/helpers";
import {
  CitySelector,
  DateSelector,
  FormTitle,
  NumberOfDaysSelector,
} from "./form";

export type RangePickerSelectedDays = {
  from: string;
  to: string;
};

type Props = {
  register: ReactHookFormRegister;
  errors: any;
  control: ReactHookFormControl;
};

const DateRange: FC<Props> = ({ register, errors, control }) => {
  const { installationDetails, setInstallationDetails } =
    useInstallationStore();

  const { global } = useGlobalStore();
  const permissionPrice = usePermissionPrice();
  const withOutsideLiftPrice = useWithOutsideLiftPrice();
  const { t } = useTranslation();

  const { setAmount } = useOrder();

  const lengthsOfBoard = [
    "5m (PKW)",
    "10m (Transporter)",
    "15m (LKW bis 3,5 t)",
    "20m (LKW bis 7,5 t)",
    "25m (LKW mit Anhänger)",
  ];

  const handlePermissionChange = (e?: React.ChangeEvent<HTMLInputElement>) => {
    !installationDetails.withPermission
      ? setAmount((amount) => amount + permissionPrice)
      : setAmount((amount) => amount - permissionPrice);
    e &&
      setInstallationDetails({
        withPermission: !installationDetails.withPermission,
      });
  };

  const handleWithOutsideLiftChange = (
    e?: React.ChangeEvent<HTMLInputElement>
  ) => {
    !installationDetails.withOutsideLift
      ? setAmount((amount) => amount + withOutsideLiftPrice)
      : setAmount((amount) => amount - withOutsideLiftPrice);
    e &&
      setInstallationDetails({
        withOutsideLift: !installationDetails.withOutsideLift,
      });
  };

  const handleTwoSiteBlockChange = (
    e?: React.ChangeEvent<HTMLInputElement>
  ) => {
    !installationDetails.twoSiteBlock
      ? setAmount((amount) => amount + TWO_SIDE_BLOCKING_PRICE)
      : setAmount((amount) => amount - TWO_SIDE_BLOCKING_PRICE);

    e &&
      setInstallationDetails({
        twoSiteBlock: !installationDetails.twoSiteBlock,
      });
  };

  const endOfTimeFrom = Number(installationDetails.timeFrom.substring(0, 2));
  const startOfTimeTill = endOfTimeFrom < 12 ? 12 : endOfTimeFrom + 1; // timeFrom should be less than timeTill and default to 12

  const isDateEmpty = !installationDetails.dateRange.from;

  return (
    <div>
      <div className="border">
        <FormTitle />
        <CitySelector control={control} errors={errors} />
        <DateSelector />
        <NumberOfDaysSelector control={control} />
        <div className="row form-group">
          <div className="col-12">
            <input
              className="form-control"
              {...register("streetAndHouseNumber")}
              type="text"
              id="streetAndHouseNumber"
              placeholder={t(
                "installations:inputs:streetAndHouseNumber:placeholder"
              )}
              value={installationDetails.streetAndHouseNumber}
              disabled={isDateEmpty}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setInstallationDetails({
                  streetAndHouseNumber: e.target.value,
                });
              }}
            />
            <p
              className={classNames({
                required: true,
                "display-none": !global.showRequired,
              })}
            >
              {errors?.streetAndHouseNumber?.message}
            </p>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-12">
            <input
              className="form-control"
              {...register("zipCode")}
              type="number"
              id="zipCode"
              placeholder={t("installations:inputs:zipCode:placeholder")}
              value={installationDetails.zipCode}
              disabled={isDateEmpty}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setInstallationDetails({
                  zipCode: e.target.value,
                });
              }}
            />
            <p
              className={classNames({
                required: true,
                "display-none": !global.showRequired,
              })}
            >
              {errors?.zipCode?.message}
            </p>
          </div>
        </div>
        <div className="form-group">
          <Controller
            control={control}
            name="purpose"
            render={({ field: { ref, onChange } }) => (
              <Select
                ref={ref}
                id="purpose"
                placeholder={t("installations:inputs:purpose:placeholder")}
                options={purposeOptions}
                isDisabled={isDateEmpty}
                onChange={(e) => {
                  setInstallationDetails({ purpose: e ? e.value : "" });
                  return onChange(purposeOptions.map((option) => option.value));
                }}
              />
            )}
          />
          <p
            className={classNames({
              required: true,
              "display-none": !global.showRequired,
            })}
          >
            {errors.purpose?.message}
          </p>
        </div>
        <div className="row form-group">
          <div className="col-6">
            <Controller
              control={control}
              name="timeFrom"
              render={({ field: { ref, onChange } }) => (
                <Select
                  ref={ref}
                  id="timeFrom"
                  placeholder={t("contactDetails:inputs:timeFrom:placeholder")}
                  options={getAllTime(6, 16).map((time) => ({
                    label: time,
                    value: time,
                  }))}
                  isDisabled={isDateEmpty}
                  onChange={(e) => {
                    setInstallationDetails({ timeFrom: e ? e.value : "" });
                    return onChange(e?.value);
                  }}
                />
              )}
            />
            <p
              className={classNames({
                required: true,
                "display-none": !global.showRequired,
              })}
            >
              {errors.timeFrom?.message}
            </p>
          </div>
          <div className="col-6">
            <Controller
              control={control}
              name="timeTill"
              render={({ field: { ref, onChange } }) => (
                <Select
                  ref={ref}
                  id="timeTill"
                  placeholder={t("contactDetails:inputs:timeTill:placeholder")}
                  options={getAllTime(startOfTimeTill, 22).map((time) => ({
                    label: time,
                    value: time,
                  }))}
                  isDisabled={isDateEmpty}
                  onChange={(e) => {
                    setInstallationDetails({ timeTill: e ? e.value : "" });
                    return onChange(e?.value);
                  }}
                />
              )}
            />
            <p
              className={classNames({
                required: true,
                "display-none": !global.showRequired,
              })}
            >
              {errors.timeTill?.message}
            </p>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-12">
            <Controller
              control={control}
              name="lengthOfBoard"
              render={({ field: { ref, onChange } }) => (
                <Select
                  ref={ref}
                  id="lengthOfBoard"
                  placeholder={"Länge"}
                  options={lengthsOfBoard.map((len) => ({
                    label: len,
                    value: len,
                  }))}
                  isDisabled={isDateEmpty}
                  onChange={(e) => {
                    setInstallationDetails({ lengthOfBoard: e ? e.value : "" });
                    return onChange(e?.value);
                  }}
                />
              )}
            />
            <p
              className={classNames({
                required: true,
                "display-none": !global.showRequired,
              })}
            >
              {errors.lengthOfBoard?.message}
            </p>
          </div>
        </div>
        <div className="row form-group">
          <div>
            <input
              type="checkbox"
              id="twoSiteBlock"
              onChange={!isDateEmpty ? handleTwoSiteBlockChange : () => {}}
              checked={installationDetails.twoSiteBlock}
              disabled={isDateEmpty}
            />
            <label htmlFor="twoSiteBlock">
              {t("installations:checkboxes:twoSiteBlock:label")}
            </label>
          </div>

          <div>
            <input
              type="checkbox"
              id="permission"
              onChange={!isDateEmpty ? handlePermissionChange : () => {}}
              checked={installationDetails.withPermission}
              disabled={isDateEmpty}
            />
            <label htmlFor="permission">
              {t("installations:checkboxes:withPermission:label")}
            </label>
          </div>
        </div>
        <div className="row form-group">
          <div>
            <input
              type="checkbox"
              id="withOutsideLift"
              onChange={!isDateEmpty ? handleWithOutsideLiftChange : () => {}}
              checked={installationDetails.withOutsideLift}
              disabled={isDateEmpty}
            />
            <label htmlFor="withOutsideLift">
              {t("installations:checkboxes:withOutsideLift:label")}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export { DateRange };
