import create from "zustand";

interface UserDetailsState {
  userDetails: {
    salutation: string;
    firstName: string;
    lastName: string;
    companyName: string;
    email: string;
    telephoneNumber: string;
    contactStreetAndHouseNumber: string;
    contactCityAndZipCode: string;
    notes: string;
  };
  setUserDetails: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;

  resetUserDetails: () => void;
}

const initialState = {
  salutation: "",
  firstName: "",
  lastName: "",
  companyName: "",
  email: "",
  telephoneNumber: "",
  contactStreetAndHouseNumber: "",
  contactCityAndZipCode: "",
  notes: "",
};

const useUserStore = create<UserDetailsState>((set) => ({
  userDetails: initialState,
  setUserDetails: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) =>
    set((state) => ({
      ...state,
      userDetails: {
        ...state.userDetails,
        [e.target.name]: e.target.value,
      },
    })),

  resetUserDetails: () =>
    set((state) => ({
      ...state,
      userDetails: initialState,
    })),
}));

export { useUserStore };
