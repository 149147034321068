import create from "zustand";

type Files = File[];

type FilesState = {
  files: Files;
  setFiles: (files: Files) => void;
};

const initialState = [] as Files;

const useFilesStore = create<FilesState>((set) => ({
  files: initialState,
  setFiles: (data) => set(() => ({ files: data })),
}));

export { useFilesStore };
