import classNames from "classnames";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useGlobalStore, useInstallationStore, useUserStore } from "src/store";
import UploadComponent from "./form/FileUploadControl";

const ContactDetailsForm: FC<any> = ({
  register,
  errors,
  control,
  setError,
}) => {
  const { t } = useTranslation();
  const { userDetails, setUserDetails } = useUserStore();
  const { global } = useGlobalStore();
  const { installationDetails } = useInstallationStore();

  const isDateEmpty = !installationDetails.dateRange.from;

  return (
    <div className="checkout-form">
      <div className="border">
        <form>
          <div className="form-title">{t("contactDetails:title")}</div>
          <div className="row form-group">
            <div className="col-6">
              <Controller
                control={control}
                name="salutation"
                render={({ field: { ref, onChange } }) => (
                  <Select
                    ref={ref}
                    id="salutation"
                    placeholder={"Anrede"}
                    options={[
                      { label: "Herr", value: "Herr" },
                      { label: "Frau", value: "Frau" },
                    ]}
                    isDisabled={isDateEmpty}
                    onChange={(e) => {
                      let customE = {
                        target: {
                          name: "salutation",
                          value: e?.value,
                        },
                      } as any;
                      setUserDetails(customE);
                      return onChange(e?.value);
                    }}
                  />
                )}
              />
              <p
                className={classNames({
                  required: true,
                  "display-none": !global.showRequired,
                })}
              >
                {errors.salutation?.message}
              </p>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-6">
              <input
                className="form-control"
                {...register("firstName")}
                placeholder={t("contactDetails:inputs:firstName:placeholder")}
                value={userDetails.firstName}
                disabled={isDateEmpty}
                onChange={setUserDetails}
              />
              <p
                className={classNames({
                  required: true,
                  "display-none": !global.showRequired,
                })}
              >
                {errors.firstName?.message}
              </p>
            </div>
            <div className="col-6">
              <input
                className="form-control"
                {...register("lastName")}
                placeholder={t("contactDetails:inputs:lastName:placeholder")}
                value={userDetails.lastName}
                disabled={isDateEmpty}
                onChange={setUserDetails}
              />
              <p
                className={classNames({
                  required: true,
                  "display-none": !global.showRequired,
                })}
              >
                {errors.lastName?.message}
              </p>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-6">
              <input
                className="form-control"
                {...register("companyName")}
                placeholder={t("contactDetails:inputs:companyName:placeholder")}
                value={userDetails.companyName}
                disabled={isDateEmpty}
                onChange={setUserDetails}
              />
              <p
                className={classNames({
                  required: true,
                  "display-none": !global.showRequired,
                })}
              >
                {errors.companyName?.message}
              </p>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-6">
              <input
                className="form-control"
                {...register("email")}
                type="email"
                placeholder={t("contactDetails:inputs:email:placeholder")}
                value={userDetails.email}
                disabled={isDateEmpty}
                onChange={setUserDetails}
              />
              <p
                className={classNames({
                  required: true,
                  "display-none": !global.showRequired,
                })}
              >
                {errors.email?.message}
              </p>
            </div>
            <div className="col-6">
              <input
                className="form-control"
                {...register("telephoneNumber")}
                type="number"
                placeholder={t(
                  "contactDetails:inputs:telephoneNumber:placeholder"
                )}
                value={userDetails.telephoneNumber}
                disabled={isDateEmpty}
                onChange={setUserDetails}
              />
              <p
                className={classNames({
                  required: true,
                  "display-none": !global.showRequired,
                })}
              >
                {errors.telephoneNumber?.message}
              </p>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-12">
              <input
                className="form-control"
                {...register("contactStreetAndHouseNumber")}
                placeholder={t(
                  "contactDetails:inputs:contactStreetAndHouseNumber:placeholder"
                )}
                disabled={isDateEmpty}
                value={userDetails.contactStreetAndHouseNumber}
                onChange={setUserDetails}
              />
              <p
                className={classNames({
                  required: true,
                  "display-none": !global.showRequired,
                })}
              >
                {errors.contactStreetAndHouseNumber?.message}
              </p>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-12">
              <input
                className="form-control"
                {...register("contactCityAndZipCode")}
                placeholder={t(
                  "contactDetails:inputs:contactCityAndZipCode:placeholder"
                )}
                value={userDetails.contactCityAndZipCode}
                disabled={isDateEmpty}
                onChange={setUserDetails}
              />
              <p
                className={classNames({
                  required: true,
                  "display-none": !global.showRequired,
                })}
              >
                {errors.contactCityAndZipCode?.message}
              </p>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-12">
              <textarea
                {...register("notes")}
                rows="5"
                charswidth="23"
                style={{ width: "100%", height: "100%" }}
                placeholder={t("contactDetails:inputs:notes:placeholder")}
                value={userDetails.notes}
                disabled={isDateEmpty}
                onChange={setUserDetails}
              />
              <p
                className={classNames({
                  required: true,
                  "display-none": !global.showRequired,
                })}
              >
                {errors.notes?.message}
              </p>
            </div>
          </div>
          <UploadComponent
            control={control}
            setError={setError}
            errors={errors}
          />
        </form>
      </div>
    </div>
  );
};

export { ContactDetailsForm };
