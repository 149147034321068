import { numberOfDaysSelection } from "src/utils/constants";
import create from "zustand";

type NumberOfDays = {
  value: string;
  label: string;
};

type NumberOfDaysState = {
  numberOfDays: NumberOfDays;
  setNumberOfDays: (numberOfDays: NumberOfDays) => void;
  resetNumberOfDays: () => void;
};

const daysDropdownValues = numberOfDaysSelection();

const initialState = daysDropdownValues[0];

const useNumberOfDaysStore = create<NumberOfDaysState>((set) => ({
  numberOfDays: initialState,
  setNumberOfDays: (data) => set((state) => ({ ...state, numberOfDays: data })),
  resetNumberOfDays: () =>
    set((state) => ({ ...state, numberOfDays: initialState })),
}));

export { useNumberOfDaysStore };
