import dayjs from "dayjs";
import { calculatePerDayAmount, getAllDaysIn4Months } from "src/utils/helpers";
import { makeRequest } from "src/utils/makeRequest";
import { APIResponse, CityResponse } from "src/types";
import { PriceTableSchema } from "src/components/PaypalCheckoutButton";

type PaymentData = any;

type City = {
  basePrice: number;
  lowestPrice: number;
};

const requestPayment = async ({
  order,
  userDetails,
  installationDetails,
  type = "payed",
  priceTableInformation,
}: {
  order: PaymentData;
  userDetails: any;
  installationDetails: any;
  type: string;
  priceTableInformation: PriceTableSchema[];
}) => {
  const formattedInstallationDetails = { ...installationDetails };
  if (installationDetails.city.id) {
    formattedInstallationDetails.city = installationDetails.city.id;
  }
  return makeRequest("payment/pay", {
    method: "POST",
    data: {
      order,
      userDetails,
      installationDetails: formattedInstallationDetails,
      type,
      priceTableInformation,
    },
  });
};

const uploadFiles = async (installationId: string, files: File[]) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("file", file);
  });

  return makeRequest(`payment/${installationId}/upload`, {
    method: "POST",
    data: formData,
  });
};

const getAllCities = async (): Promise<APIResponse<CityResponse[]>> => {
  let response = await makeRequest("/city/all");
  if (response) {
    const now = new Date();
    let allMonths = getAllDaysIn4Months(now.getFullYear(), now.getMonth());
    response.data.forEach((city: City) => {
      let allPrice: number[] = [];

      allMonths.forEach((from: string) => {
        let calculatedAmount = calculatePerDayAmount(
          dayjs().diff(from, "day"),
          city.basePrice,
          from
        );
        calculatedAmount && allPrice.push(calculatedAmount);
      });
      city.lowestPrice = Math.min(...allPrice);
    });
  }
  return response;
};

export { requestPayment, getAllCities, uploadFiles };
