import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

type OrderContextSchema = {
  amount: number;
  setAmount: Dispatch<SetStateAction<number>>;
};

const OrderContext = createContext<OrderContextSchema>({
  amount: 0,
  setAmount: () => {},
});

const OrderContextProvider = ({ children }: any) => {
  const [amount, setAmount] = useState<number>(0);

  return (
    <OrderContext.Provider value={{ amount, setAmount }}>
      {children}
    </OrderContext.Provider>
  );
};

const useOrder = () => {
  return useContext(OrderContext);
};

export { OrderContextProvider, useOrder };
