import { useTranslation } from "react-i18next";
import { useGlobalStore, useInstallationStore } from "src/store";

export default function FormTitle() {
  const { installationDetails } = useInstallationStore();
  const { global } = useGlobalStore();
  const { t } = useTranslation();

  const showCurrentCity = () => {
    if (global.cities.length) {
      return global.cities.find(
        (city) => installationDetails.city?.id === city.value
      );
    }
    return null;
  };

  const currentCity = showCurrentCity();

  return (
    <div className="form-title">
      {t("installations:title")}
      {currentCity ? (
        <>
          <p style={{ fontSize: "26px", fontWeight: "800" }}>
            {currentCity.labelWithLowestPrice}
          </p>
          <p style={{ fontSize: "20px", fontWeight: "800" }}>
            ({currentCity.netto}€ netto)
          </p>
        </>
      ) : null}
    </div>
  );
}
