import {
  FALLBACK_BASE_PRICE,
  FALLBACK_OUTSIDE_LIFT_PRICE,
  FALLBACK_PERMISSION_PRICE,
} from "src/utils/constants";
import create from "zustand";

type City = {
  label: string;
  value: string;
  basePrice: number;
  permissionPrice: number;
  withOutsideLiftPrice: number;
  labelWithoutLowestPrice: string;
  labelWithLowestPrice: string;
  netto: string;
  slug: string;
};

interface GlobalState {
  global: {
    showRequired: boolean;
    basePrice: number;
    permissionPrice: number;
    withOutsideLiftPrice: number;
    cities: City[];
    submitType: string;
    isSubmitting: boolean;
  };
  changeShowRequired: () => void;
  setBasePrice: (value: number) => void;
  setPermissionPrice: (value: number) => void;
  setWithOutsideLiftPrice: (value: number) => void;
  setCities: (cities: City[]) => void;
  setSubmitType: (submitType: string) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
}

const initialState = {
  showRequired: false,
  basePrice: FALLBACK_BASE_PRICE,
  permissionPrice: FALLBACK_PERMISSION_PRICE,
  withOutsideLiftPrice: FALLBACK_OUTSIDE_LIFT_PRICE,
  cities: [],
  submitType: "prepayment",
  isSubmitting: false,
};

const useGlobalStore = create<GlobalState>((set) => ({
  global: initialState,
  changeShowRequired: () =>
    set((state) => {
      return {
        global: {
          ...state.global,
          showRequired: true,
        },
      };
    }),
  setBasePrice: (value: number) =>
    set((state) => {
      return {
        global: {
          ...state.global,
          basePrice: value,
        },
      };
    }),
  setPermissionPrice: (value: number) =>
    set((state) => {
      return {
        global: {
          ...state.global,
          permissionPrice: value,
        },
      };
    }),
  setWithOutsideLiftPrice: (value: number) =>
    set((state) => {
      return {
        global: {
          ...state.global,
          withOutsideLiftPrice: value,
        },
      };
    }),
  setCities: (cities) =>
    set((state) => {
      return {
        global: {
          ...state.global,
          cities,
        },
      };
    }),
  setSubmitType: (submitType: string) =>
    set((state) => {
      return {
        global: {
          ...state.global,
          submitType,
        },
      };
    }),
  setIsSubmitting: (isSubmitting: boolean) =>
    set((state) => {
      return {
        global: {
          ...state.global,
          isSubmitting,
        },
      };
    }),
}));

export { useGlobalStore };
