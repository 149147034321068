import { initReactI18next } from "react-i18next";
import i18n from "i18next";
// import languageDetector from 'i18next-browser-languagedetector'
import { en, de } from "./locales";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en,
      de,
    },
    lng: "de",

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
