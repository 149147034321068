const isFormControl = (type: string): boolean => {
  if (
    type === "text" ||
    type === "number" ||
    type === "email" ||
    type === "password"
  ) {
    return true;
  }
  return false;
};

const FormInput = ({ type = "text", onChange, value, ...rest }: any): any => {
  return (
    <div>
      <input
        className={isFormControl(type) ? "form-control" : ""}
        type={type}
        value={value}
        onChange={onChange}
        {...rest}
      />
      {/* <strong>Error</strong> */}
    </div>
  );
}

export { FormInput };
