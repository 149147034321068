import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useOrder } from "src/contexts";
import {
  useBasePrice,
  usePermissionPrice,
  useWithOutsideLiftPrice,
} from "src/hooks/helperHooks";
import { useInstallationStore, useNumberOfDaysStore } from "src/store";
import { ReactHookFormControl } from "src/types";
import {
  TWO_SIDE_BLOCKING_PRICE,
  extraDayPrice,
  numberOfDaysSelection,
  permissionForElevatorPrice,
} from "src/utils/constants";
import { calculatePerDayAmount, getExtraDays } from "src/utils/helpers";

export type RangePickerSelectedDays = {
  from: string;
  to: string;
};

type Props = {
  control: ReactHookFormControl;
};

const daysDropdownValues = numberOfDaysSelection();

export default function NumberOfDaysSelector({ control }: Props) {
  const { installationDetails, setInstallationDetails } =
    useInstallationStore();

  const { numberOfDays, setNumberOfDays } = useNumberOfDaysStore();

  const basePrice = useBasePrice();
  const permissionPrice = usePermissionPrice();
  const withOutsideLiftPrice = useWithOutsideLiftPrice();
  const { t } = useTranslation();
  const { setAmount } = useOrder();

  const isDateEmpty = !installationDetails.dateRange.from;

  return (
    <div className="form-group">
      <Controller
        control={control}
        name="numberOfDays"
        render={({ field: { ref, onChange } }) => (
          <>
            <label htmlFor="numberOfDays">
              {t("installations:inputs:numberOfDays:label")}:
            </label>
            <Select
              ref={ref}
              id="numberOfDays"
              placeholder={t("installations:inputs:numberOfDays:label")}
              options={daysDropdownValues}
              value={numberOfDays}
              isDisabled={isDateEmpty}
              onChange={(e) => {
                let dateRange = {
                  from: installationDetails.dateRange.from,
                  to: e
                    ? dayjs(installationDetails.dateRange.from)
                        .add(+e.value - 1, "day")
                        .format("YYYY-MM-DD")
                    : "",
                };
                setInstallationDetails({
                  dateRange,
                });

                // TODO: refactor the logic
                let calculatedAmount = calculatePerDayAmount(
                  dayjs().diff(dateRange.from, "day"),
                  basePrice,
                  dateRange.from
                );
                let extraDays = getExtraDays(dateRange.from, dateRange.to);

                setAmount(
                  calculatedAmount +
                    extraDays * extraDayPrice +
                    (installationDetails.withPermission ? permissionPrice : 0) +
                    (installationDetails.withOutsideLift
                      ? withOutsideLiftPrice
                      : 0) +
                    (installationDetails.permissionForElevator
                      ? permissionForElevatorPrice
                      : 0) +
                    (installationDetails.twoSiteBlock
                      ? TWO_SIDE_BLOCKING_PRICE
                      : 0)
                );

                e && setNumberOfDays(e);
                return onChange(
                  daysDropdownValues.map((option) => option.value)
                );
              }}
            />
          </>
        )}
      />
    </div>
  );
}
