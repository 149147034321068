import { OrderContextProvider } from "src/contexts";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import './i18n';
import reportWebVitals from "./reportWebVitals";
import "./style.css"

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <OrderContextProvider>
      <App />
    </OrderContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
